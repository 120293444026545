import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    username:'XXXXX', //用户名称
    usermoney:'0', //用户的玩家币
    dropout:false,//是否显示退出登陆按钮
    footershow:false,
    currentindex:1,
    login_title:"",
    phone:"",
    global_url:"https://gg-web.wanjiayizhan.com/Store",
    global_tk:"",
    global_tk_url:"",
    isloginshow:false,//登录框
    downfile:{
      filename:"玩家驿站.exe",   
      url:"https://cdn-wjyz.wanjiayizhan.com/download/WJYZPlatform-1.0.7.exe"
      },
    downfile_pubg_bd:{
        filename:"玩家驿站.exe",
        url:"https://cdn-wjyz.wanjiayizhan.com/channel/WJYZPlatform-106.exe"
        },
    downfile_diablo_bd:{
      filename:"玩家驿站.exe",
      url:"https://cdn-wjyz.wanjiayizhan.com/channel/WJYZPlatform-107.exe"
      },
      downfile_pubg_360:{
        filename:"玩家驿站.exe",
        url:"https://cdn-wjyz.wanjiayizhan.com/channel/WJYZPlatform-109.exe"
        },
    downfile_diablo_360:{
      filename:"玩家驿站.exe",
      url:"https://cdn-wjyz.wanjiayizhan.com/channel/WJYZPlatform-110.exe"
      },
      downfile_bd_list:[
        {  //bd_pubg
          filename:"玩家驿站.exe",
          channelNumber:'117',
          url:"https://cdn-wjyz.wanjiayizhan.com/channel/WJYZPlatform-117.exe"
          },
        {  //bd_gta
          filename:"玩家驿站.exe",
          channelNumber:'118',
          url:"https://cdn-wjyz.wanjiayizhan.com/channel/WJYZPlatform-118.exe"
          },
        {  //bd_艾尔登
          filename:"玩家驿站.exe",
          channelNumber:'119',
          url:"https://cdn-wjyz.wanjiayizhan.com/channel/WJYZPlatform-119.exe"
          },
        {  //bd_2077
          filename:"玩家驿站.exe",
          channelNumber:'120',
          url:"https://cdn-wjyz.wanjiayizhan.com/channel/WJYZPlatform-120.exe"
          },
        {  //bd_星空
          filename:"玩家驿站.exe",
          channelNumber:'121',
          url:"https://cdn-wjyz.wanjiayizhan.com/channel/WJYZPlatform-121.exe"
          },
          {  //bd_会员页面
            filename:"玩家驿站.exe",
            channelNumber:'122',
            url:"https://cdn-wjyz.wanjiayizhan.com/channel/WJYZPlatform-122.exe"
            },
      ],
      downfile_360_list:[
        {  //360_pubg
          filename:"玩家驿站.exe",
          channelNumber:'123',
          url:"https://cdn-wjyz.wanjiayizhan.com/channel/WJYZPlatform-123.exe"
          },
        {  //360_gta
          filename:"玩家驿站.exe",
          channelNumber:'124',
          url:"https://cdn-wjyz.wanjiayizhan.com/channel/WJYZPlatform-124.exe"
          },
        {  //360_艾尔登
          filename:"玩家驿站.exe",
          channelNumber:'125',
          url:"https://cdn-wjyz.wanjiayizhan.com/channel/WJYZPlatform-125.exe"
          },
        {  //360_2077
          filename:"玩家驿站.exe",
          channelNumber:'126',
          url:"https://cdn-wjyz.wanjiayizhan.com/channel/WJYZPlatform-126.exe"
          },
        {  //360_星空
          filename:"玩家驿站.exe",
          channelNumber:'127',
          url:"https://cdn-wjyz.wanjiayizhan.com/channel/WJYZPlatform-127.exe"
          },
          {  //360_会员页面
            filename:"玩家驿站.exe",
            channelNumber:'128',
            url:"https://cdn-wjyz.wanjiayizhan.com/channel/WJYZPlatform-128.exe"
            },
      ],
      downfile_main_bd:{
        filename:"玩家驿站.exe",
        url:"https://cdn-wjyz.wanjiayizhan.com/channel/WJYZPlatform-129.exe"
        },
        downfile_main_360:{
          filename:"玩家驿站.exe",
          url:"https://cdn-wjyz.wanjiayizhan.com/channel/WJYZPlatform-130.exe"
          },
      channel_code:'',  
      downfile_channel_list:[
        {
          name:'ZZ',
          down_code:{type:0,gameName:'ZZ加速器'},
          code:'000101',
          channelNumber:101,
          filename:"玩家驿站.exe",
          url:"https://cdn-wjyz.wanjiayizhan.com/channel/WJYZPlatform-101.exe"

        },
        {
          name:'AK',
          down_code:{type:0,gameName:'AK加速器'},
          code:'000102',
          channelNumber:102,
          filename:"玩家驿站.exe",
          url:"https://cdn-wjyz.wanjiayizhan.com/channel/WJYZPlatform-102.exe"
        },
        {
          name:'野豹',
          down_code:{type:0,gameName:'野豹加速器'},
          code:'000103',
          channelNumber:103,
          filename:"玩家驿站.exe",
          url:"https://cdn-wjyz.wanjiayizhan.com/channel/WJYZPlatform-103.exe"
        },
        {
          name:'TM',
          down_code:{type:0,gameName:'TM加速器'},
          code:'000104',
          channelNumber:104,
          filename:"玩家驿站.exe",
          url:"https://cdn-wjyz.wanjiayizhan.com/channel/WJYZPlatform-104.exe"
        },
      ],
      dialogVisible:false,
      current_nav_index:1,
      com_show:{
        Top:true,
        FooterNav:true,
        Login:true,
        Cusservice:true,
      },
      buywindow:false,
      buywindowHY:false,

      buywindow_tg:false,
      buywindowHY_tg:false,
  },
  getters: {
  },
  mutations: {
  },
  actions: {
  },
  modules: {
  }
})
